import { createRouter, createWebHashHistory } from "vue-router"
// import { auth } from "./firebaseConfig"

const routes = [
    {
        path : '/',
        name : 'main',
        component : () => import('./main.vue'),        
    },
]

export default createRouter({
    history : createWebHashHistory(),
    routes
})