import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';

import { createApp } from 'vue'
import App from './App.vue'

import PrimeVue                 from 'primevue/config';
import Card                     from 'primevue/card';
import Button                   from 'primevue/button';
import InputText                from 'primevue/inputtext';
import InputNumber              from 'primevue/inputnumber';
import InputMask                from 'primevue/inputmask';
import Checkbox                 from 'primevue/checkbox';
import MultiSelect              from 'primevue/multiselect';
import Dropdown                 from 'primevue/dropdown';
import Listbox                  from 'primevue/listbox';
import DataTable                from 'primevue/datatable';
import DataView                 from 'primevue/dataview';
import DataViewLayoutOptions    from 'primevue/dataviewlayoutoptions';
import Column                   from 'primevue/column';
import Tooltip                  from 'primevue/tooltip';
import Toolbar                  from 'primevue/toolbar';
import ToggleButton             from 'primevue/togglebutton';
import Sidebar                  from 'primevue/sidebar';
import Panel                    from 'primevue/panel';
import Textarea                 from 'primevue/textarea';
import FileUpload               from 'primevue/fileupload'
import Calendar                 from 'primevue/calendar';
import Carousel                 from 'primevue/carousel';
import Dialog                   from 'primevue/dialog';
import Galleria                 from 'primevue/galleria';
import ScrollPanel              from 'primevue/scrollpanel';
import ConfirmationService      from 'primevue/confirmationservice'
import ConfirmDialog            from 'primevue/confirmdialog'
import TriStateCheckBox         from 'primevue/tristatecheckbox'
import Steps                    from 'primevue/steps' ;
import SelectButton             from 'primevue/selectbutton' ;
import MenuBar                  from 'primevue/menubar' ;
import Toast                    from 'primevue/toast';
import ToastService             from 'primevue/toastservice';
import TreeTable                from 'primevue/treetable';
import Dock                     from 'primevue/dock';
import Password                 from 'primevue/password';

import store                    from './storage/storage' ;
import axios                    from 'axios' ;
import VueAxios                 from 'vue-axios'
// import { auth }                 from './firebaseConfig';
import router                   from './router';

const app = createApp(App)

app.use(VueAxios, axios)
app.use(PrimeVue);
app.use(store) ;
app.use(router) ;
app.directive('tooltip', Tooltip);
app.use(ToastService);
app.use(ConfirmationService)


store.dispatch('loadData');

app.component('Toast',                  Toast);
app.component('MenuBar',                MenuBar);
app.component('Steps',                  Steps);
app.component('Button',                 Button);
app.component('Card',                   Card);
app.component('InputText',              InputText);
app.component('InputNumber',            InputNumber);
app.component('InputMask',              InputMask);
app.component('Checkbox',               Checkbox);
app.component('MultiSelect',            MultiSelect);
app.component('Dropdown',               Dropdown);
app.component('Listbox',                Listbox);
app.component('SelectButton',           SelectButton);
app.component('TriStateCheckBox',       TriStateCheckBox);
app.component('Column',                 Column);
app.component('DataTable',              DataTable);
app.component('DataView',               DataView);
app.component('DataViewLayoutOptions',  DataViewLayoutOptions);
app.component('Toolbar',                Toolbar);
app.component('ToggleButton',           ToggleButton);
app.component('Sidebar',                Sidebar);
app.component('Panel',                  Panel);
app.component('TextArea',               Textarea);
app.component('FileUpload',             FileUpload);
app.component('Calendar',               Calendar);
app.component('Carousel',               Carousel);
app.component('Dialog',                 Dialog);
app.component('Galleria',               Galleria);
app.component('ScrollPanel',            ScrollPanel);
app.component('ConfirmDialog',          ConfirmDialog);
app.component('TreeTable',              TreeTable);
app.component('Dock',                   Dock);
app.component('Password',               Password);

// import './registerServiceWorker'

app.mount('#app')
