import axios            from 'axios';
import { createStore }  from 'vuex';
// import { db,firebase, storage }  from '../firebaseConfig'

export default createStore({
    state       : {
        kobosData   : [],
    },
    getters     : {
        getData:(state)                     => state.kobosData,
    },
    mutations   : {
        setKobosData:(state, informacion)   => state.data = informacion,
    },
    actions     : {
        loadData({commit}){
            axios.defaults.headers.common = {
                "Access-Control-Allow-Origin"   : "*",
                'Content-Type'                  : 'application/json',
                "X-API-Key"                     : 'Token 0bdfdf2fc140ef6d32bd04f541a680cd60add29c'
            }
            axios.defaults.withCredentials = true ;
            axios.request({
                url     : `${process.env.VUE_APP_API_ENDPOINT}/api/v2/assets/a8PAn4HbBmViWBJpPoSEbk/data/?format=json`,
                method  : 'GET'
            }).then(res => {
                res.data.forEach(info => {
                    commit('setKobosData',info)
                })
            },
            error => console.log('Error de informacion',error))
        },
    }
})

